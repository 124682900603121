import { Injectable } from '@angular/core';
import { HttpResponseBase, HttpHeaders, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http'
import { AppInfoService } from './app.info.service';
import { Observable, Subject } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { UserModel, JWTokenModel, ListResponse, } from '../models';
import { EntityResponse } from '../models/reponses/entity.response';
import { AppUtilities } from './app.utilities';
import { Appkeys } from './app.keys';
import { DrillSurvayModel, } from '../models/drillSurvay.model';
import { WellModel } from '../models/well.model';
import { WellGeonomicFileModel } from '../models/well-geonomicfile.model';
import { GeonomicFileModel } from '../models/geonomic-file.model';
import { CloudProcess } from '../models/cloudprocess.model';
import { TubePlotModel } from '../models/TubePlot.model';
import { CloudProcessRequest } from '../models/CloudProcessrequest.model';
import { CloudProcessViewModel } from '../models/CloudProcessViewModel';
import { SiteModel } from '../models/site.model';

@Injectable({ providedIn: 'root' })
export class CloudProcessService {

  tokenValue: JWTokenModel;
  baseUrl: string;
  token: string;
  errorMessage: string;

  constructor(public router: Router, public http: HttpClient, appInfo: AppInfoService) {
    this.baseUrl = appInfo.apiBaseUrl;
    this.http = http;
    this.token = localStorage.getItem(Appkeys.ACCESS_TOKEN);
  }

  LoadWellDataProcess(): Observable<ListResponse<WellModel>> {
    var subject = new Subject<any>();
    this.http.get<WellModel>(this.baseUrl + '/wells/GetAllWells', { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      subject.next(result);
      return subject.asObservable();
    },
      error => {
        subject.next(error);
      }
    );

    return subject.asObservable();
  }

  GetAllWellBySiteId(siteId:number): Observable<ListResponse<WellModel>> {
    var subject = new Subject<any>();
    this.http.get<SiteModel>(this.baseUrl + '/wells/getallwellbysiteid/?siteId=' + siteId, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      subject.next(result);
      return subject.asObservable();
    },
      error => {
        subject.next(error);
      }
    );

    return subject.asObservable();
  }


  LoadDrillSurveyGeonomics(wellId: number): Observable<ListResponse<GeonomicFileModel>> {
    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.get(this.baseUrl + '/DirllSurvay/GetAllDrillSurveyFiles?wellId=' + wellId, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      return subject.next(result);
    },
      error => {
        return subject.next(error);
      }
    );


    return subject.asObservable();
  }


  LoadGLassFileGenomics(wellId: number): Observable<ListResponse<GeonomicFileModel>> {
    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.get(this.baseUrl + '/DirllSurvay/GetWellGonomicFile?wellId=' + wellId, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      return subject.next(result);
    },
      error => {
        return subject.next(error);
      }
    );


    return subject.asObservable();
  }


  SavetheCloudProcess(cloudProcess: CloudProcess): Observable<EntityResponse<CloudProcess>> {

    var subject = new Subject<any>();
    subject.next(null);
    this.http.post<EntityResponse<CloudProcess>>(this.baseUrl + '/DirllSurvay/InsertCloudProcess', AppUtilities.JSonstringify(cloudProcess), { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      subject.next(result);
    },
      error => {
        subject.next(error);
      }
    );

    return subject.asObservable();
  }

  GetCloudProcessId(cloudprocessreuest: CloudProcess): Observable<EntityResponse<CloudProcess>> {
    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.post<EntityResponse<CloudProcess>>(this.baseUrl + '/DirllSurvay/PerfectCloud', AppUtilities.JSonstringify(cloudprocessreuest), { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      subject.next(result);
      return subject.asObservable();
    },
      error => {
        subject.next(error);
      }
    );

    return subject.asObservable();
  }


  GetCloudProcessFromId(cloudProcessId: number): Observable<EntityResponse<CloudProcessViewModel>> {
    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.get<EntityResponse<CloudProcessViewModel>>(this.baseUrl + '/DirllSurvay/GetALlCloudProcessFromId?cloudProcess=' + cloudProcessId, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
      subject.next(result);
      return subject.asObservable();
    },
      error => {
        subject.next(error);
      }
    );

    return subject.asObservable();
  }


  //RegisterUser(user: UserModel): Observable<EntityResponse<UserModel>> {
  // var subject = new Subject<any>();
  //this.http.post<UserModel>(this.baseUrl + '/users/insertuser', AppUtilities.JSonstringify(user), { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
  //  subject.next(result);
  //  return subject.asObservable();
  //},
  //  error => {
  //   subject.next(error);
  // }
  //);


  DeleteCloudProcess(cloudProcess: CloudProcess): Observable<EntityResponse<CloudProcess>> {

    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.post<EntityResponse<number>>(this.baseUrl + '/DirllSurvay/DeleteCloudProcess', AppUtilities.JSonstringify(cloudProcess),
      { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
        subject.next(result);
        return subject.asObservable();
      },
        error => {
          subject.next(error);
        }
      );

    return subject.asObservable();


  }

  GenerateSteppingData(drillSurveyId: number, step: number): Observable<number> {
    var subject = new Subject<any>();
    subject.next(null);
    let resultvalue = null;

    this.http.post<EntityResponse<number>>(this.baseUrl + '/DrillSurvey/generatesteppingdatabydrillsurveyId?drillSurveyId=' + drillSurveyId + '&step=' + step,null,
      { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
        subject.next(result);
        return subject.asObservable();
      },
        error => {
          subject.next(error);
        }
      );

    return subject.asObservable();


  }




}
