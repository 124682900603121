import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  template: `
  <div class="modal-header">
  <h5 class="modal-title pull-left">{{header}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
    <span aria-hidden="true">×</span>
  </button>
</div>
  <div class="modal-body text-center">
                       <p>{{prompt}}</p>
            <button type="button" class="btn btn-primary" (click)="confirm()" >Yes</button>
           <button type="button" class="btn btn-danger" (click)="decline()" >No</button>
           </div>`
})
export class ConfirmModalComponent implements OnInit {

  prompt:string;
  header:string;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  confirm() {
    if (this.bsModalRef.content.callback != null) {
      this.bsModalRef.content.callback('yes');
      this.bsModalRef.hide();
    }
  }

  decline() {
    if (this.bsModalRef.content.callback != null) {
      this.bsModalRef.content.callback('no');
      this.bsModalRef.hide();
    }
  }
}