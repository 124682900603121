import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, navItemsAdmin } from '../../_nav';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItems:any;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  private authservice: AuthService;
  public userName: any;

  constructor(authservice:AuthService,@Inject(DOCUMENT) _document?: any,) {

    this.authservice=authservice;
    this.loadNavigations();

    this.userName = this.authservice.getCurrentUserName();
    console.log(this.userName);
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  loadNavigations(){
    var role=this.authservice.getCurrentUserRole();
    if (role =="SuperAdmin" ){
      this.navItems = navItems;
    }else {
      this.navItems =navItemsAdmin;
    }
  }

  logOut(){
    this.authservice.logOut();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
