import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
// import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from "../../services/auth.service";
import { LoginModel, JWTokenModel } from '../../models';
import { Subscription } from 'rxjs';
import { TosterService } from '../../services/toster.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'

})
export class LoginComponent implements OnInit, OnDestroy  {
  loginForm: FormGroup;
  submitted = false;
  loginmodle = null;
  subscription: Subscription;
  constructor(private router: Router, private formBuilder: FormBuilder, private authservice: AuthService,  private toastr: TosterService) { }

  RedirectToRegister() {
    this.router.navigateByUrl('/register');
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() { return this.loginForm.controls; }

  Login() {
    this.submitted = true;

    const logmodle = new LoginModel();

    logmodle.username = this.loginForm.get('username').value,
    logmodle.password = this.loginForm.get('password').value


    if (!this.loginForm.invalid) {
      this.subscription=this.authservice.logIn(logmodle).subscribe(result=>{
        this.toastr.ShowError("User Name or Password in Incorrect", "Geonomics");
      });

    }
  }

  ngOnDestroy() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
}

}
