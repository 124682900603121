import { BaseModel } from './base.model';
import {CompanyModel} from './company.model'
import { RoleModel } from './role.model';
import { UserRoleModel } from './user-role.model';

export class UserModel extends BaseModel{
  username: string;
  email: string;
  password: string;
  firstName:string;
  lastName:string;
  address: string;
  city: string;
  stateProvince: string;
  postalZipCode: string;
  country: string;
  roleId:number;
  companyId:number;
  initials:string;
  companyName:string;
  company:CompanyModel;
  userRoles:UserRoleModel[];

}
