import { Injectable } from '@angular/core';
import { HttpResponseBase, HttpHeaders, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http'
import { AppInfoService } from './app.info.service';
import { Observable, Subject } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import {SiteModel } from '../models/site.model';
import {CompanyModel } from '../models/company.model';
import { JWTokenModel } from '../models/';
import { EntityResponse } from '../models/reponses/entity.response';
import { ListResponse } from '../models/reponses/list.response';
import { AppUtilities } from './app.utilities';
import { Appkeys } from './app.keys';

@Injectable({ providedIn: 'root' })
export class SiteService {

    tokenValue: JWTokenModel;
    baseUrl: string;
    token:string;
    errorMessage: string;

    constructor(public router: Router, public http: HttpClient, appInfo: AppInfoService) {
        this.baseUrl = appInfo.apiBaseUrl;
        this.http = http;
        this.token = localStorage.getItem(Appkeys.ACCESS_TOKEN);
    }
    RegisterSites(siteModel: SiteModel): Observable<SiteModel> {
        var subject = new Subject<any>();
        subject.next(null);
        this.http.post<SiteModel>(this.baseUrl + '/sites/insertsite', AppUtilities.JSonstringify(siteModel), { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
            subject.next(result);
        },
            error => {
                subject.next(error);
            }
        );

        return subject.asObservable();
    }
    EditSite(siteModel: SiteModel): Observable<EntityResponse<SiteModel>> {
        var subject = new Subject<any>();
        this.http.put<SiteModel>(this.baseUrl + '/sites/updatesite', AppUtilities.JSonstringify(siteModel), { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
            subject.next(result);
            return subject.asObservable();
        },
            error => {
                subject.next(error);
            }
        );

        return subject.asObservable();
    }
    loadSiteDetails(siteModel: SiteModel): Observable<EntityResponse<SiteModel>> {
        var subject = new Subject<any>();
        subject.next(null);
        let resultvalue = null;

        this.http.get<SiteModel>(this.baseUrl + '/sites/getbyidsite?siteId=' + siteModel.id, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
            return subject.next(result);
        },
            error => {
                return subject.next(error);
            }
        );


        return subject.asObservable();
    }


  LoadAllSiteDetails(): Observable<ListResponse<SiteModel>> {
    console.log(AppUtilities.getHttpHeadersApplicationJson())
    var subject = new Subject<any>();
    this.http.get<SiteModel>(this.baseUrl + '/sites/getallsite', { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {

      console.log(result)
      return subject.next(result);

    },
      error => {
        return subject.next(error);
      }
    );
    return subject.asObservable();
  }

  LoadAllSiteDetailsByCompanyId(compantId:number): Observable<ListResponse<SiteModel>> {
    console.log(AppUtilities.getHttpHeadersApplicationJson())
    var subject = new Subject<any>();
    this.http.get<SiteModel>(this.baseUrl + '/sites/getallsitebycompanyid?companyId='+compantId, { headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {

      console.log(result)
      return subject.next(result);

    },
      error => {
        return subject.next(error);
      }
    );
    return subject.asObservable();
  }
  }
