import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LoginModel, JWTokenModel } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtilities } from './app.utilities';
import { Appkeys } from './app.keys';
import { AppInfoService } from './app.info.service';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class TosterService {
 

  constructor(public router: Router, public http: HttpClient, appInfo: AppInfoService,private toastr: ToastrService) { 
  }

  public showSuccess(message:string,header:string) {
    this.toastr.success(message, header);
  }

  public ShowInfo(message:string,header:string) {
    this.toastr.info(message, header);
  }

  public ShowError(message:string,header:string) {
    this.toastr.error(message, header);
  }

  public ShowWarning(message:string,header:string) {
    this.toastr.warning(message, header);
  }

 

 
}