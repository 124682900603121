import { BaseModel } from './base.model';

 export class DataTableParamtersModel {

  offset: number;
  limit: number;
  sortCol: string;
  sortDirection:string;
  searchText:string;

}